/**
 * 接口域名的管理
 */
// 正式配置
// const base = {
//
//   //sq:'/api',//请求代理需要用到
//     sq:'http://wechat.nxptdn.com',
//     bd: 'http://operator.nxptdn.com',
//     client:'http://e.nxptdn.com',
//     appid:'wx9e5e67da26c51a14'
// }


//演示环境 
const base = {
  sq: '/prod-api',//请求代理需要用到
  bd: 'https://charge.cqzdxny.cn/prod-api',
  // bd: 'http://localhost:9090',

  client: 'https://charge-dl.cqzdxny.cn',
  //公众号
  appid: 'wx34077fb1b5c1ddbb'
}





export default base;


